<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print ">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Item</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Item
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Item</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_item')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', { id: 'newItem', mode: 'create' })
          "
        >
          New Item
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head no-print pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select v-model="params.size" @change="filterItems" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="">All</option>
          </select>
           <select class="filter-input mr-2 mg-t-5" v-model="params.group" @change="filterItems">
            <option value="" selected>Select Group</option>
            <option
            v-for="(group, index) in itemElements.group"
            :key="index"
            :value="group.id"
          >
            {{ group.title }}
          </option>
          </select>
          <select class="filter-input mr-2 mg-t-5" v-model="params.unit_type" @change="filterItems">
            <option value="" selected>Select Unit Type</option>
            <option
            v-for="(unit_type, index) in itemElements.unit_type"
            :key="index"
            :value="unit_type.id"
          >
            {{ unit_type.title }}
          </option>
          </select>
          <select class="filter-input mr-2 mg-t-5" v-model="params.brand" @change="filterItems">
            <option value="" selected>Select Brand</option>
            <option
            v-for="(brand, index) in itemElements.brands"
            :key="index"
            :value="brand.id"
          >
            {{ brand.name }}
          </option>
          </select>
          <select class="filter-input mr-2 mg-t-5" v-model="params.stock" @change="filterItems">
            <option value="">Select Stock Type</option>
            <option value="1">Stockable</option>
            <option value="0">Non-Stockable</option>
          </select>
          <select class="filter-input mr-2 mg-t-5" v-model="params.vat" @change="filterItems">
            <option value="">Select Vat Type</option>
            <option value="1">Vat Applicable</option>
            <option value="0">Vat Not Applicable</option>
          </select>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="params.searched"
            class="float-right"
            placeholder="Search..."
            @keyup="filterItems"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
               <th class="wd-10p">Code</th>
              <th class="wd-15p">Item Name</th>
              <th class="wd-15p" v-if="itemElements.item_setting.extra_detail==1">Extra Details</th>
              <th class="wd-10p">Category</th>
              <th class="wd-10p">Brand</th>
              <th class="wd-10p">Unit Type</th>
              <th class="wd-5p">Stockable</th>
              <th class="wd-10p">VAT Applicable</th>
              <th class="wd-10p text-center table-end-item no-print" v-if="checkIfAnyPermission(['view_item','edit_item','delete_item'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && itemLists.length > 0">
            <tr v-for="(item, index) in itemLists" :key="index">
              <th scope="row" class="table-start-item">{{ pageSerialNo+index }}.</th>
              <td>{{item.item_code}}</td>
              <td>
               {{ item.name }}
              </td>
              <td v-if="itemElements.item_setting.extra_detail==1"><div v-if="item.extra_details.length > 0">
                  <div v-for="(detail) in item.extra_details" :key="detail.id">
                    {{detail.title}} - {{detail.value}}<br/>
                  </div>
                </div>
                <div v-else>-</div></td>
              <td>{{ item.item_group.title }}</td>
              <td><span v-if="item.brand_id">{{item.brand.name}}</span></td>
              <td>{{ item.unit_type.title }}</td>
              <td>{{item.stock_required == 1?'Yes':'No'}}
              <td>{{ item.vat_applicable==1?'Applicable':'Not Applicable' }}</td>
              <td class="text-center table-end-item no-print" v-if="checkIfAnyPermission(['view_item','edit_item','delete_item'])">
                <a href="javascript:;" class="mr-2">
                  <i
                    class="fas fa-eye tx-success"
                    @click="view('itemDetail', item.id)"
                    title="View"
                    v-if="checkSinglePermission('view_item')"
                  ></i>
                </a>
                <a
                  href="javascript:;"
                  class="mr-2"
                  @click="edit('editItem', item.id)"
                   v-if="checkSinglePermission('edit_item')"
                  title="Edit"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a href="javascript:;" @click="drop(item.id)" title="Delete"  v-if="checkSinglePermission('delete_item')">
                  <i class="fa fa-trash" title="Delete"></i>
                </a>
              </td>
            </tr>
          </tbody>
           <tbody v-else-if="!loading && itemLists.length == 0">
            <tr>
              <td  colspan="11" style="text-align:center;">
                No records found.
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td  colspan="11" style="text-align:center;">
                Loading.....
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <td  colspan="11" style="text-align:center;">
                {{error_message}}
              </td>
          </tbody>
        </table>
        <Paginate v-model="page" :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
          />
        <ItemCreate @parent-event="getData()" />
        <ItemEdit @parent-event="getData()"/>
        <ItemDetail />
      </div>
    </div>
  </div>
</template>
<script>
import ItemCreate from "./NewItem";
import ItemEdit from "./EditItemNew";
import ItemDetail from "./ItemDetail";
import { mapGetters } from "vuex";
import Paginate from 'vuejs-paginate';
import Services from "./Services/Services";
import _ from 'lodash';
export default {
  components: {
    ItemCreate,
    ItemEdit,
    Paginate,
    ItemDetail,
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("items",['itemLists','itemElements']),
  },
  data(){
    return{
        params:{
          size:'10',
          group:'',
          unit_type:'',
          searched:'',
          brand:'',
          vat:'',
          stock:'',
          offset:0,
        },
        loading:true,
        error:false,
        error_message:'',
        page:1,
        pageSerialNo:1,
        totalPageCount:0,
    }
  },
  methods: {
    clickCallback: function(page) {
      if(page == 1){
        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.size) + 1 ;
      }
      this.params.offset = (page-1) * this.params.size;
      this.getData();
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      // this.$store.commit("setDataId", id);
      // this.$store.commit("dropRecord", `api/item/${id}`);
      this.$swal({
          title: 'Do you really want to delete Item ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Delete',
          cancelButtonText: 'No',
          showLoaderOnConfirm: true,
          preConfirm:() => {
          // delete lead
             return Services.deleteItem(id).then((response) => {
              if(response.status == 200){
                  let success = response.data.success_message;
                  this.setNotification(success);
              }
              this.getData();
          }).catch(error => { 
               this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
          }
      })
    },
     setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
    getData() {
      this.loading = true;
      Services.getItemElements().then(res=>{
        this.$store.commit("items/setItemElements",res.data.data);
        Services.getFilteredItemLists(this.params).then(res=>{
        this.$store.commit("items/setItemLists",res.data.data.data);
        this.totalPageCount = res.data.data.total_no_of_pages;
        this.loading=false;
      }).catch(err=>{
        if (err.response.data.error_message == "Access Denied.") {
          this.error_message = "Access Denied !!!";
        } else {
          this.error_message = "Error Fetching data from the server.";
        }
        this.loading=false;
        this.error=true;
        
      });
      }).catch(err=>{
          console.log(err);
      });
      
      
    },
    
    filterItems: _.debounce(function(){
      this.loading = true;
      this.pageSerialNo=1,
      this.params.offset =0;
      this.page=1;
      Services.getFilteredItemLists(this.params).then(res=>{
          this.$store.commit("items/setItemLists",res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
      }).catch(err=>{
          if (err.response.data.error_message == "Access Denied.") {
            this.error_message = "Access Denied !!!";
          } else {
            this.error_message = "Error Fetching data from the server.";
          }
          this.error = true;
          let error = err.response.data.error_message;
          this.setNotification(error);
      }).finally(()=>{
        this.loading=false;
      })
    },1000),
  },
  mounted() {
    this.getData();
  },
  watch: {
    
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
<style>
  @media print {
    @page {
      size: auto;
      margin: 20px 10px;
    }
    .no-print, .no-print *
    {
        display: none !important;
    }
    .table2 thead tr, .table thead tr th {
      color: #000;
      font-weight: 500 !important;
    }
  }
</style>