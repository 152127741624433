<template>
  <div class="col-sm-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'itemDetail'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header no-print">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Item Detail - {{ details.item_code }}
                </h5>
              </div>
              <div class="col-sm-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print" @click="print()" title="Print"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  @click="$store.dispatch('modalClose')" title="Close"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-theam-secondary">
            <div class="modal-body row">
              <div class="col-sm-6 pd-r-7-5">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-1">
                          <div class="col-sm-5 mt-0 mb-1">
                            <div class="wd-50 ht-50" v-if="details.image">
                              <img :src="details.image" alt="" />
                            </div>
                            <div class="wd-50 ht-50" v-else>
                              <img src="/assets/img/logosmall.png" alt="" />
                            </div>
                          </div>
                          <div class="col-sm-7 pl-0 pb-0">
                            <div class="mx-1 mb-1">
                              <div>Item Name :</div>
                              <div class="text-primary">{{details.name}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="row m-0 mb-1 ">
                          <div class="col-sm-5 m-0 p-0">Item Group</div>
                          <div class="col-sm-7 m-0 p-0">: {{ details.item_group.title }}</div>
                        </div>
                        <div class="row m-0">
                          <div class="col-sm-5 m-0 p-0">Item Brand</div>
                          <div class="col-sm-7 m-0 p-0">: <span v-if="details.brand_id">{{ details.brand.name}}</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div
                        class="group-attribute-container overflow-scroll"
                        style="height: 2.3rem"
                      >{{details.description}}</div>
                    </div>
                    <div class="group pd-2 mt-3" id="extra-details" v-if="details.extra_details.length > 0 ">
                      <label class="group-label">Extra Details</label>
                      <div class="row m-0" v-for="(field) in details.extra_details" :key="field.id">
                          <div class="col-sm-5 m-0 p-0">{{field.title}}</div>
                          <div class="col-sm-7 m-0 p-0">:{{ field.value}}</div>
                        </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="col-sm-6 pd-l-7-5">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="group mb-3">
                      <label class="group-label">{{(details.stock_required == 1)?'Stock Setting':'Pricing Information'}}</label>
                      <div class="group-attribute-container">
                        <div class="row mx-0 mb-1">
                          <div class="col-sm-5 m-0 p-0">Stock Required</div>
                          <div class="col-sm-7 m-0 p-0">: {{ details.stock_required==0?'No':'Yes' }}</div>
                        </div>
                        <div class="row mx-0 mb-1" v-if="details.stock_required==1">
                          <div class="col-sm-5 m-0 p-0">Min Stock</div>
                          <div class="col-sm-7 m-0 p-0">: <span v-if="details.min_stock">{{ details.min_stock }}-{{ details.unit_type.title }}</span><span v-else>-</span></div>
                        </div>
                        <div class="row m-0" v-if="details.stock_required==1">
                          <div class="col-sm-5 m-0 p-0">Expiry</div>
                          <div class="col-sm-7 m-0 p-0">: <span v-if="details.day_count">{{ details.day_count }} Days </span><span v-else>-</span></div>
                        </div>
                        <div class="row mx-0 mb-1" v-if="details.stock_required==0">
                          <div class="col-sm-5 m-0 p-0">Sales Rate</div>
                          <div class="col-sm-7 m-0 p-0">: {{parseDigitForSlip(details.sales_rate)}}</div>
                        </div>
                        <div class="row mx-0" v-if="details.stock_required==0">
                          <div class="col-sm-5 m-0 p-0">Discount</div>
                          <div class="col-sm-7 m-0 p-0">: {{details.discount}} %</div>
                        </div>
                        <div class="row mx-0" v-if="details.stock_required==0">
                          <div class="col-sm-5 m-0 p-0">Discount Amount</div>
                          <div class="col-sm-7 m-0 p-0">: {{details.discount_amount}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Unit Type</label>
                      <div class="group-attribute-container">
                        <div class="row m-0">
                          <div class="col-sm-5 m-0 p-0">Unit</div>
                          <div class="col-sm-7 m-0 p-0">: {{ details.unit_type.title }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">VAT Setting</label>
                      <div class="group-attribute-container">
                        <div class="row m-0">
                          <div class="col-sm-5 m-0 p-0">VAT Appicable</div>
                          <div class="col-sm-7 m-0 p-0">: {{ details.vat_applicable==1?'Yes':'No' }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      details: [],
    };
  },
  methods:{
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    print(){
      window.print();
    }
  },
  computed: {
    ...mapGetters([
      "dataLists",
      "dataId",
      "modalId",
      "modalMode",
    ]),
    ...mapGetters("items",["itemLists","itemElements"])
  },
  watch: {
    modalId(value) {
      if(value == 'itemDetail'){
        let id = this.dataId;
         this.details = this.itemLists.find(function (data) {
          return data.id == id;
        });
      }
     
    },
  }
};
</script>
<style>
.print-detail-only {
  display: none;
}
  @media print {
    @page {
      size: auto;
      margin: 20px 10px;
    }
    .no-print, .no-print *
    {
        display: none !important;
    }
    .modal-dialog {
        max-width: 100% !important;
        margin: 0 auto;
    }
    .modal-dialog-centered {
        display: block;
    }
    /* .modal-content {
      border: none !important;
    } */
  }
</style>
